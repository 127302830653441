exports.components = {
  "component---src-components-templates-categories-js": () => import("./../../../src/components/templates/categories.js" /* webpackChunkName: "component---src-components-templates-categories-js" */),
  "component---src-components-templates-page-js": () => import("./../../../src/components/templates/page.js" /* webpackChunkName: "component---src-components-templates-page-js" */),
  "component---src-components-templates-post-js": () => import("./../../../src/components/templates/post.js" /* webpackChunkName: "component---src-components-templates-post-js" */),
  "component---src-components-templates-projects-js": () => import("./../../../src/components/templates/projects.js" /* webpackChunkName: "component---src-components-templates-projects-js" */),
  "component---src-components-templates-search-js": () => import("./../../../src/components/templates/search.js" /* webpackChunkName: "component---src-components-templates-search-js" */),
  "component---src-components-templates-singel-story-js": () => import("./../../../src/components/templates/singelStory.js" /* webpackChunkName: "component---src-components-templates-singel-story-js" */),
  "component---src-components-templates-story-js": () => import("./../../../src/components/templates/story.js" /* webpackChunkName: "component---src-components-templates-story-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apply-js": () => import("./../../../src/pages/apply.js" /* webpackChunkName: "component---src-pages-apply-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-khulna-js": () => import("./../../../src/pages/khulna.js" /* webpackChunkName: "component---src-pages-khulna-js" */),
  "component---src-pages-storytwo-js": () => import("./../../../src/pages/storytwo.js" /* webpackChunkName: "component---src-pages-storytwo-js" */)
}

